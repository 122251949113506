
<span v-if="document.isFolder" class="text-muted">
  {{ __('documents.created-on-date', { date: document.createdAt }) }}
</span>
<span v-else class="text-muted">
  {{
    document.isUploaded
      ? __('documents.uploaded-by')
      : __('documents.generated-by')
  }}
  <strong v-if="document.user">{{ document.user.name }}</strong>
  {{ __('documents.on') }}
  {{ document.createdAt }}
</span>
